.body-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.video-container {
  margin-bottom: 100px;
}
